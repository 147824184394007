/* src/components/DashboardPage.css */
.dashboard-page {
    padding: 20px;
  }
  
  .student-form, .students-list, .workshops-list {
    margin-bottom: 20px;
  }
  
  .studentLabe {
    display: block;
    width: 600px;
    margin-bottom: 10px;
    border: solid 1px ;
    background-color: rgb(253, 181, 168);
  }
  
  form input, form button {
    margin-top: 5px;
  }
  
  button {
    margin-left: 10px;
  }
  .dashboard-page{
    background-color: aliceblue;
  }
  .dashLi{
    background-color: rgb(7, 187, 133);
    
    width: 80%;
    display: flex;
    justify-content: space-around;

  }
  