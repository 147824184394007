/* Header Styles */
.Header {
  background-color: #23032b;
  align-items: center;
  padding: .8rem 0;
  position: sticky;
  top: 0;
  z-index: 1000;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12px;
  height: 7vh;
  justify-content: center;
  width: 100%;

}

.containerheader {
  display: flex;
  justify-content: space-between;
  margin-right: 4rem;
  ;
}

.logo {
  width: 250px;
  height: 60px;
  margin-left: 50px;
  box-shadow: 1px 1px 5px 1px rgb(255, 254, 254);
  /* Add shadow to logo */
  border-radius: 10px;



}


.buttons {


  padding: 0.75rem 1.5rem;
  border-radius: 4px;
  color: rgb(247, 250, 250);
  background-color: #f54803;

}

.gotoclass {

  margin-left: 15px;
  background-color: #f57803;
  color: #f0ece6;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  font-size: 12px;

}


nav ul {

  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

nav ul li span {
  margin-top: 10px;
  margin-left: 2rem;
  padding: 0.5rem 1rem;
  text-decoration: none;

}



nav ul li a:hover {
  color: #fcff49;
  background-color: #f57803;
  padding: .5rem;
}



nav ul li a {
  color: #f0ece6;
  text-decoration: none;
  font-size: 15px;
}

.navItem {
  margin-right: 1.5rem;
  padding-right: 1.5rem;
  padding-left: 1rem;
  padding-top: .7rem;
  padding-bottom: 0rem;
  height: 25px;
  text-align: center;
  font-size: 12px;
  color: #f8f8f8;
  background-color: #fd891b;
  cursor: pointer;
  border-radius: 8px;
}



@media(max-width:768px) {

  .navH{
    margin-left: 15px;
  }

  .navItem {
    margin-right: 0.5rem;
    padding-right: .5rem;
    padding-left: .5rem;
    margin-top: 10px;
    padding-bottom: 0rem;
    height: 15px;
    text-align: center;
    font-size: 12px;
    color: #f8f8f8;
    background-color:#23032b;

    cursor: pointer;
    border-radius: 4px;
    font-size: 10px;
  }


  .containerheader {
    display: flex;
    justify-content: space-between;
    margin-right: 2rem;
    flex-direction: column;
  }

  .logo {
    width: 90px;
    height: 25px;
    margin-left: 10px;
    box-shadow: 1px 1px 5px 1px rgb(253, 252, 251);
    /* Add shadow to logo */
    border-radius: 5px;

  }

  nav ul {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  nav ul li {
    margin-left: .2rem;
  }

  nav ul li a {
    color: #f0ece6;
    text-decoration: none;
    font-size: 10px;
  }

  nav ul li a:hover {
    color: #fcff49;
    background-color: #f57803;
    padding: .2rem;
  }


  .apply {

    margin: .3rem;
    padding: 0.3rem .5rem;
    border-radius: 4px;
    font-size: 10px;

  }

  .buttons {
    margin-top: 8px;

    padding: 0.3rem 1rem;
    border-radius: 4px;
    color: rgb(247, 250, 250);
    background-color: #f53b03;
    font-size: 10px;


  }


  header {
    flex-direction: column-reverse;
    /* Reverse the order on smaller screens */
    height: 30px;
    /* Auto height to fit content */

  }


}