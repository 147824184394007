/*body{font-family: Arial, sans-serif;
line-height: 1.5;
margin: 20px;
text-align: justify;
text-justify: auto;
}
*/
.mainPage {
    display: flex;
    flex-direction: row;
   
    height: auto; /* Set the height to full viewport height */

  
}
.mainWeek {
    font-size: 18px;
    font-weight: 400;
    color: #ffc9c9;
    transition: all 0.4s ease;
    display: block;
}

/* Detail course section */
.detailCourse {
    display: flex;
    flex-direction: column; /* Ensure content flows vertically */
    margin-top: 100px;
    width: 80%;
    height: auto;;
    margin-left: 20%; /* Align to the right of the sidebar */
    background: #fcfbf4;
    transition: all 0.5s ease;
    padding: 1rem; /* Add some padding */
    overflow-y: auto; /* Enable vertical scrolling */
    height: calc(100vh - 160px); /* Adjust height based on the fixed top menu height */
}

/* Sidebar navigation */

/* Sidebar navigation styling */
.sideBarNav {
    position: fixed;
    width: 19%;
    top: 160px;
    left: 10px;
    height: calc(100vh - 160px);
    background: #0b0449;
    transition: all 0.3s ease;
    color: #f9f9f9;
    overflow-y: auto; /* Enable vertical scrolling */
    padding-top: 20px;
}

/* Sidebar menu items */
.sideBarNav ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.sideBarNav ul a {
    font-size: 15px;
    display: block;
    padding: 5px 10px;
    color: #f9f9f9;
    text-decoration: none; /* Remove underline */
    transition: background-color 0.3s ease, color 0.3s ease;
    background-color: #413b5c;
}

.sideBarNav ul a:hover  {
    background-color: #9e8eee; /* Darker shade of the sidebar background */
    color: #ffffff; /* White text on hover */
}
/* Top navigation welcome section */
ul li a .selected {
    background-color: #82923c; /* Color for the selected link */
    color: #ffffff; /* White text */
}
    .navWelcom {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    background-color:#0f0547;
    padding: 1rem 2rem; /* Adjust padding as needed */
    z-index: 1000; /* Ensure it stays above other elements */
}

/* Top navigation menu */
.navMenu {
    position : fixed;
    display: flex;
    justify-content:flex-start;
 
    gap: 2rem;
    width: 100vw;
 background-color: #413b5c;
    color: #f1f0f5;
    padding-top: 1rem;
    margin-top: 80px; /* Ensure it's placed below the welcome section */
    text-decoration: none;;
}

/* Main week styling */


.weekclass {
    display: none;
    background-color: rgb(19, 26, 26);
}

.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
}

.dropdown-content.show {
    display: block;
}

.submenu {
    display: none;
}

.submenu.show {
    display: block;
}

.weekclass {
    display: none; /* Initially hide the weekclass */
}

.block {
    display: block; /* Display when the block class is added */
}

.weekclass11, .weekclass21, .weekclass31 {
    display: none; /* Initially hide all weekclass elements */
}

.blocks {
    display: block !important; /* Ensure block class overrides */
}
h1{
    color: #ff5f02;
}
h2{
    color: #ff7a0e;
}

p {
    margin: 10px 0;
}

ul {
    margin: 10px 0;
    padding-left: 20px;
}

li {
    margin-bottom: 5px;
    text-decoration: none;
}
/* public/styles.css */


.login-container {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 300px;
    text-align: center;
}

.login-container h2 {
    margin-bottom: 20px;
}

.login-container input {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.login-container button {
    padding: 10px;
    width: 100%;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: white;
    font-size: 16px;
}

.login-container button:hover {
    background-color: #0056b3;
}
