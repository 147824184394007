body {
  background: #2a212c;
  /* fallback for old browsers */
  text-align: center;

}
.navItems{
  background-color: #f56607;
  cursor: pointer;
  padding-left: 10px;
  padding-right: 10px;
}

.main {
  display: flex;
  flex-direction: column;

}

.usersAdded {
  background-color: #f2f2f2;
  width: 500px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem
}
.studentCred{
color: rgb(241, 227, 227);

}
.formLogin {
  margin-top: 23vh;
  margin-left: 40vw;

  z-index: 1;
  background: #cabfc9;
  max-width: 360px;

  padding: 45px;
  text-align: center;
  box-shadow: 0 0 10px 0 rgb(21, 11, 24), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
}

.formLogin input {
  font-family: "Roboto", sans-serif;
  outline: 0;
  background: #f2f2f2;
  width: 100%;
  border: 0;
  margin: 0 0 15px;
  padding: 15px;
  box-sizing: border-box;
  font-size: 14px;
}

.form button {
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  outline: 0;
  background: #100dd4;
  width: 100%;
  border: 0;
  padding: 15px;
  color: #FFFFFF;
  font-size: 14px;
  -webkit-transition: all 0.3 ease;
  transition: all 0.3 ease;
  cursor: pointer;
}

.invitation {
  color: rgb(58, 56, 56);

  text-align: center;
  font-size: 14px;
  font-family: Arial, Helvetica, sans-serif;
  margin: 0 auto;
  /* Centers the block horizontally if necessary */
}

.invitation a {
  color: rgb(255, 102, 0);
  /* Adjust the link color as needed */
  text-decoration: underline;
  background-color: #fbf8fc;
}

.invitation a:hover {
  color: #f00;
  /* Adjust the hover color as needed */
}

.codefor {
  position: absolute;
  margin-top: 1rem;
  margin-left: 3rem;
  color: #a82801;
}

@media(max-width:768px) {


  .formLogin {
    margin-top: 10vh;

    margin-left: 3vw;
    margin-right: 3vw;
    border-radius: .5rem;

    z-index: 1;
    background: #cabfc9;
    max-width: 360px;

    padding: 25px;

    z-index: 1;
    background: #cabfc9;


    text-align: center;
    box-shadow: 0 0 10px 0 rgb(21, 11, 24), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
  }

  .formLogin input {
    font-family: "Roboto", sans-serif;
    outline: 0;
    background: #ececec;
    width: 100%;
    border: 0;
    margin: 15px 5px 5px;
    padding: 10px;
    box-sizing: border-box;
    font-size: 10px;
  }

  .form button {
    font-family: "Roboto", sans-serif;
    text-transform: uppercase;
    outline: 0;
    background: #100dd4;
    width: 80px;
    border: 0;
    padding: 15px;
    color: #FFFFFF;
    font-size: 14px;
    -webkit-transition: all 0.3 ease;
    transition: all 0.3 ease;
    cursor: pointer;
  }

  .invitation {
    color: rgb(58, 56, 56);

    text-align: center;
    font-size: 14px;
    font-family: Arial, Helvetica, sans-serif;
    margin: 0 auto;
    /* Centers the block horizontally if necessary */
  }

  .invitation a {
    color: rgb(255, 102, 0);
    /* Adjust the link color as needed */
    text-decoration: underline;
    background-color: #fbf8fc;
  }

  .invitation a:hover {
    color: #f00;
    /* Adjust the hover color as needed */
  }

  .codefor {
    position: absolute;
    margin-top: 1rem;
    margin-left: 3rem;
    color: #a82801;
  }



}