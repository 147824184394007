
.footer {
  background-color:#240424;
    color: #312f2f;
    padding: 2rem 0;
    text-align: center;
    color: azure;
    font-size: smaller;
  }
  
  .footer-nav {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-top: 1rem;
    font-size: x-small;
  }
  
  .footer-nav a {
    color: #00bcd4;
    text-decoration: none;
    font-size: 1rem;
    font-size: x-small;
  }
  
  .footer-nav a:hover {
    text-decoration: underline;
  }

  @media(max-width:760){
    
  }