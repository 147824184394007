/* FAQ Section Styles */
.faq {
  background-color:#e6c7f0;
    color: #443d3d;
    padding: 3rem 0;
  }
  
  .faq-headline {
    font-size: 2.5rem;
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .faq-accordion {
    max-width: 800px;
    margin: 0 auto;
  }
  
  .faq-item {
    margin-bottom: 1rem;
  }
  
  .faq-question {
    background-color: #2a2a2a;
    color: #fff;
    padding: 1rem;
    border: none;
    width: 100%;
    text-align: left;
    font-size: 1.25rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .faq-question:hover {
    background-color: #333;
  }
  
  .faq-answer {
    width: 800px;
    background-color: #670574;
    color: #fff;
    padding: 1rem;
    border: none;
   
    cursor: pointer;
    transition: background-color 0.3s ease;
    padding: 1.3rem;
    text-align: center;
  
  }
  
  .faq-answer p {
    margin: 0;
  }

  @media(max-width : 762px) {

    /* FAQ Section Styles */
.faq {
  background-color:#e6c7f0;
    color: #443d3d;
    padding: 1rem 0;
  }
  
  .faq-headline {
    font-size: 2.5rem;
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .faq-accordion {
    max-width: 90%;
    margin: 0 auto;

  }
  
  .faq-item {
    margin-bottom: 1rem;
  }
  
  .faq-question {
    background-color: #2a2a2a;
    color: #fff;
    padding: 1rem;
    border: none;
    width: 100%;
    text-align: left;
    font-size: 1.25rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .faq-question:hover {
    background-color: #333;
  }
  
  .faq-answer {
    width: 90%;
    background-color: #670574;
    color: #fff;
    padding: 1rem;
    border: none;
   
    cursor: pointer;
    transition: background-color 0.3s ease;
    padding: 1.3rem;
    text-align: center;
  
  }
  
    
  }