/* Testimonials Section Styles */

  

  
.welcom {
  display: flex;
  height: 50vh; /* Adjust height as needed */
  padding-top: 3rem;
  background-color:#1a0020;
  padding-bottom: 2rem;
  gap: 1rem;

}

.welcom-image {
  display: flex;
 
  flex: 1;
  height: auto; /* Ensure the height adjusts according to the width */
  /*overflow: hidden; /* Ensure no overflow */
}

.welcoming {
  width: 100%;
  height: auto; 
  transition: opacity 10s ease-in-out; /* Transition time set to 0.2 seconds */
  opacity: 5;/* Maintain aspect ratio */
  /*display: block; /* Remove any gaps below the image */

}



.welcom-content {
  display: flex;
  flex : 1;
  place-items: center;
  container-type: inline-size;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  
  overflow:inherit;
}

.welcom-headline {
 
  color: #fd6f11;
  font-size: 3em;
  margin-bottom: 1rem;

}

.welcom-subheadline {
  font-size: 1.3em;
  margin-bottom: 1em;
  color: #bdb0a8;
 
  ;
}

.cta-button {
  padding: 1.75rem 1.5rem;
  border-radius: 4px;
  text-decoration: none;
  font-size: 1.5rem;
  color: #fff;
  background-color: #fa8602;
  transition: background-color 0.3s ease;
}

.cta-button:hover {
  background-color: #b30095;
}



/* media phone*/


/* Media query for screens up to 768px */

@media (max-width: 1568px) {


  
.welcom-headline {
 
  color: #fd6f11;
  font-size: 2.5em;
  margin-bottom: 1rem;

}

.welcom-subheadline {
  font-size: .8em;
  margin-bottom: 1em;
  color: #bdb0a8;
 
  ;
}
}
@media (max-width: 968px) {

    .welcom {
      flex-direction: column; /* Stack items vertically */
      height: auto; /* Adjust height as needed */
      padding-top: 3vh;
    }
  
    .welcom-content {
      order: 2; /* Display content first */
      padding: 0.5rem; /* Adjust padding for smaller screens */
    }
  
    .welcom-image {
      order: 1; /* Display image second */
    }
  
    .welcom-headline {
      font-size: 2rem; /* Decrease font size for smaller screens */
    }
  
    .welcom-subheadline {
      font-size: 1.2rem; /* Decrease font size for smaller screens */
    }
  
    .cta-button {
      font-size: 0.9rem; /* Decrease font size for smaller screens */
      padding: 2.5rem 1rem; /* Adjust padding for smaller screens */
    }
  }