/* ScheduleCallForm.css */
.scheduleF {
  background-color: #1a0020;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding: 4rem;
  color: #fff;
  text-align: left;
  font-family: Arial, sans-serif;
}


.schedule {
  width: 350px;
  margin: 0 auto;
  padding: 1em;
  border: 1px solid #facab3;
  border-radius: 1em;
  background-color: #f79030;
  margin-top: 1rem;
}



label {
  margin-bottom: .5em;
  color: #3a3737;
  display: block;
}

input,
textarea {
  border: 1px solid #ccc;
  padding: .5em;
  font-size: 1em;
  width: 100%;
  box-sizing: border-box;
}

button {
  padding: 0.7em;
  color: #fff;
  background-color: #40464d;
  border: none;
  border-radius: .3em;
  cursor: pointer;
}

button:hover {
  background-color: #525355;
}

@media(max-width:1000px) {


  .formC {
    width: 100%;
  }

  .titleDet {
    font-size: 1em;
    padding-bottom: 1rem;

  }

  .schedule {
    width: 100%;
    margin: 0 auto;
    padding: .5em;
    border: 1px solid #facab3;
    border-radius: 1em;
    background-color: #f1eae3;
    margin-top: 1rem;
    font-size: 12px;
    color: #fff;
  }


}